import { NAME_PLOT_ALL, PATH_PLOT_ALL } from '@/router/routes/plots'
import { NAME_CROP_ALL, PATH_CROP_ALL } from '@/router/routes/crops'
import { NAME_SETTINGS, PATH_SETTINGS } from '@/router/routes/settings'
import i18n from './libs/i18n'

export default [
  {
    name: `${i18n.t('pages.plots.name')}`,
    icon: 'fa-regular fa-grid-2',
    route: {
      name: NAME_PLOT_ALL,
      path: PATH_PLOT_ALL,
    },
  },
  {
    name: `${i18n.t('pages.crops.name')}`,
    icon: 'fa-regular fa-seedling',
    route: {
      name: NAME_CROP_ALL,
      path: PATH_CROP_ALL,
    },
  },
  {
    name: `${i18n.t('pages.settings.name')}`,
    icon: 'fa-regular fa-sliders-up',
    route: {
      name: NAME_SETTINGS,
      path: PATH_SETTINGS,
    },
  },
]
